import React, { createContext, ReactNode, useContext, useState } from 'react';

interface DrawerContextType {
    showDrawer: (content: ReactNode, title: string) => void;
    hideDrawer: () => void;
}

const DrawerContext = createContext<DrawerContextType | undefined>(undefined);

export const useDrawer = () => {
    const context = useContext(DrawerContext);
    if (!context) throw new Error('useDrawer must be used within a DrawerProvider');
    return context;
};

export const DrawerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [content, setContent] = useState<ReactNode>(null);
    const [drawerTitle, setDrawerTitle] = useState<string>('');

    const showDrawer = (content: ReactNode, title: string) => {
        setContent(content);
        setDrawerTitle(title);
        setIsOpen(true);
    };

    const hideDrawer = () => {
        setDrawerTitle('');
        setContent(null);
        setIsOpen(false);
    };

    return (
        <DrawerContext.Provider value={{ showDrawer, hideDrawer }}>
            <main
                className={
                    ' fixed overflow-hidden z-10 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out ' +
                    (isOpen
                        ? ' transition-opacity opacity-100 duration-500 translate-x-0  '
                        : ' transition-all delay-500 opacity-0 translate-x-full  ')
                }
            >
                <section
                    className={
                        ' w-screen max-w-lg right-0 absolute bg-white h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  ' +
                        (isOpen ? ' translate-x-0 ' : ' translate-x-full ')
                    }
                >
                    <article className="relative w-screen max-w-lg pb-10 flex flex-col space-y-6 overflow-y-scroll h-full">
                        <header className="p-4 font-bold text-lg">{drawerTitle}</header>
                        {content}
                    </article>
                </section>
                <section
                    className=" w-screen h-full cursor-pointer "
                    onClick={() => {
                        setIsOpen(false);
                    }}
                ></section>
            </main>
            {children}
        </DrawerContext.Provider>
    );
};
