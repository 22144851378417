import '../components/retool-drawer.js';

const template = document.createElement('template');
template.innerHTML = `
    <div className="m-8">
        <h1 className="font-bold text-2xl"></h1>
        <div class="mt-4 pt-2 w-full h-screen" id="retool">
        </div>
    </div>
`;

class RetoolAi extends HTMLElement {
    async getEmbedUrl() {
        const response = await fetch('/retool');

        if (!response.ok) {
            throw new Error(
                `HTTP error! status: (${response.status}) ${response.statusText}`,
            );
        }

        return (await response.json()).embedUrl;
    }

    constructor() {
        super();
        this.attachShadow({ mode: 'open' });

        this.shadowRoot.appendChild(template.content.cloneNode(true));
    }

    async render() {
        this.shadowRoot.querySelector('#retool').innerHTML = '';
        this.shadowRoot.querySelector('h1').innerText = this.getAttribute('name');
        const embedUrl = await this.getEmbedUrl();
        const r = document.createElement('retool-drawer');
        r.setAttribute('src', embedUrl);
        r.setAttribute('control_key', this.getAttribute('controlKey', ''));
        r.setAttribute('pillar_board_id', this.getAttribute('pillarBoardId', ''));
        this.shadowRoot.querySelector('#retool').appendChild(r);
    }

    connectedCallback() {
        this.render();
    }
}

window.customElements.define('retool-ai', RetoolAi);
