import { createRetoolEmbed } from '@tryretool/retool-embed';
import van from 'vanjs-core';
import { define } from 'vanjs-element';

const { div, span, button, h2 } = van.tags;
const { path, svg } = van.tags('http://www.w3.org/2000/svg');

/**
 * A web component wrapper around the Retool embed and drawer
 * this keeps a single instance of the app open and sends data
 * in both directions on changes.
 */
define(
    'retool-drawer',
    ({ attr, $this }) => {
        // Get the Retool embed URL from the src attribute
        const src = attr('src', '');
        const controlKey = attr('control_key').val;
        const pillarBoardId = attr('pillar_board_id').val;

        // Create the Retool embed wrapper which is really
        // an iframe under the hood
        const embed = createRetoolEmbed({
            src: src.val,
            style: 'height: 100%; width: 100%;',
            // Set default data (optional)
            data: {
                pillar_board_id: pillarBoardId,
                control_key: controlKey,
            },
            // Hook for returning data, this could be wired to
            // pass events to a global DOM event listender if
            // required.
            onData: (data) => {
                console.log('Data from Retool App: ', data);
            },
        });

        // Visible tracks the state of the drawer, potentially
        // make this an attribute so it can have a default state
        // controlled by the server.
        const visible = van.state(false);
        const wrapper = document.createElement('div');
        wrapper.appendChild(embed);

        $this.addEventListener('custom-event-name', (e) => {
            console.log('Data to Retool App: ', e.detail);
            embed.data = e.detail;
            visible.val = true;
        });

        return div(
            {
                class: 'relative z-10',
                'aria-labelledby': 'slide-over-title',
                role: 'dialog',
                'aria-modal': 'true',
            },
            div(
                {
                    class: 'pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16',
                },
                div(
                    {
                        class: () =>
                            `pointer-events-auto w-screen max-w-2xl transform transition ease-in-out duration-500 sm:duration-700 ${
                                visible.val ? 'translate-x-0' : 'translate-x-full'
                            }`,
                    },
                    div(
                        {
                            class: 'flex h-full flex-col overflow-y-scroll bg-white pt-6 shadow-xl',
                        },
                        div(
                            { class: 'px-4 sm:px-6' },
                            div(
                                { class: 'flex items-start justify-between' },
                                h2(
                                    {
                                        class: 'text-base font-semibold leading-6 text-gray-900',
                                        id: 'slide-over-title',
                                    },
                                    'Panel title',
                                ),
                                div(
                                    { class: 'ml-3 flex h-7 items-center' },
                                    button(
                                        {
                                            type: 'button',
                                            onclick: () => (visible.val = false),
                                            class: 'relative rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
                                        },
                                        span({ class: 'absolute -inset-2.5' }),
                                        span({ class: 'sr-only' }, 'Close panel'),
                                        svg(
                                            {
                                                viewBox: '0 0 24 24',
                                                class: 'h-6 w-6',
                                                fill: 'none',
                                                strokeWidth: '1.5',
                                                stroke: 'currentColor',
                                            },
                                            path({
                                                strokeLinecap: 'round',
                                                strokeLinejoin: 'round',
                                                d: 'M6 18L18 6M6 6l12 12',
                                            }),
                                        ),
                                    ),
                                ),
                            ),
                        ),
                        div(
                            {
                                id: 'retool-app',
                                class: 'relative mt-6 flex-1 px-4 sm:px-6',
                            },
                            wrapper,
                        ),
                    ),
                ),
            ),
        );
    },
    false, // Sets the shadow DOM to false
);
