import './retoolAi.js';

declare module 'react/jsx-runtime' {
    namespace JSX {
        interface IntrinsicElements {
            'retool-ai': React.DetailedHTMLProps<
                React.HTMLAttributes<HTMLElement> & {
                    src?: string;
                    controlKey?: string;
                    pillarBoardId?: number;
                    name: string;
                },
            HTMLElement>;
        }
    }
}

import { CustomCellRendererProps } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';

import { useDrawer } from '../providers/Drawer';

interface DrawerContentProps {
    url: string;
    onCancel: () => void;
    controlKey: string;
    pillarBoardId: number;
}

const DrawerContent: React.FC<DrawerContentProps> = ({
    url,
    controlKey,
    pillarBoardId,
}: DrawerContentProps) => {
    const retool: any = document.querySelector('retool-ai');
    // force re-render
    if (retool) {
        retool?.setAttribute('controlKey', controlKey);
        retool.render();
    }

    return (
        <>
            <div className="h-[calc(90vh-2rem)]">
                <retool-ai
                    name="Retool Ai Example"
                    controlKey={controlKey}
                    pillarBoardId={pillarBoardId}
                />
            </div>
        </>
    );
};

function GridCellIframeDrawer({ data: row }: CustomCellRendererProps) {
    const { showDrawer, hideDrawer } = useDrawer();
    const [auditUrl, setAuditUrl] = useState<string>('');

    const handleDrawerCancel = () => hideDrawer();

    async function getAuditUrl() {
        try {
            const response = await fetch('/audit_url');
            if (response.ok) {
                const url = await response.text();
                setAuditUrl(url);
            } else {
                console.error('Failed to fetch the Pillar URL:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching the Pillar URL:', error);
        }
    }

    useEffect(() => {
        getAuditUrl(), [];
    });

    return (
        <div className="flex flex-col">
            <div className="flex flex-col flex-grow align-bottom items-start text-xs font-medium">
                <button
                    onClick={() =>
                        showDrawer(
                            <DrawerContent
                                onCancel={handleDrawerCancel}
                                url={auditUrl}
                                controlKey={row.refId}
                                pillarBoardId={row.boardId}
                            />,
                            `${row.refId}: Sample AI Audit`,
                        )
                    }
                    className={`px-2 py-1 mt-4 bg-gray-50 text-gray-700 border border-gray-400 rounded flex gap-3`}
                >
                    Show
                </button>
            </div>
        </div>
    );
}

export default GridCellIframeDrawer;
