import React, { useState } from 'react';

import { executeScript } from '../services/utilitiesService';
import { UtilCommand } from '../Types/Util';

const Utility = ({ data }: { data: UtilCommand }) => {
    const [message, setMessage] = useState<string>('');
    const [args, setArgs] = useState<object>({});
    const [isError, setIsError] = useState<boolean>(false);

    const renderArgDesc = (args: object) => {
        let count: number = 0;
        return (
            <div>
                {Object.keys(args).map((k) => {
                    count++;
                    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                    const value: any = args[k as keyof typeof args];
                    return (
                        <div className="p-2 mb-2" key={k}>
                            <div className="flex">
                                <p>
                                    {count}. <i className="mr-2">{k}</i>
                                </p>
                                <input
                                    type="text"
                                    className="text-blue-gray-700 border px-2"
                                    name={k}
                                    placeholder={value}
                                    onChange={handleChange}
                                />
                            </div>
                            <p>
                                Description:{' '}
                                {Array.isArray(value) ? `[${value.join(', ')}]` : value}
                            </p>
                        </div>
                    );
                })}
            </div>
        );
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();
        setArgs({ ...args, [e.target.name]: e.target.value });
    };

    async function executeHandler(
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ): Promise<void> {
        e.preventDefault();
        try {
            const msg: { message: string } = await executeScript(data.endpoint, args);
            setMessage(msg.message);
            setIsError(false);
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        } catch (err: any) {
            setMessage(err.message);
            setIsError(true);
        }
    }

    return (
        <div className="m-3">
            <h3>
                Title: <span className="font-semibold">{data.name}</span>
            </h3>
            <p className="mb-3">Description: {data.description}</p>
            <p className="mb-3">Allowed Roles: {data.roles.join(', ')}</p>
            <div>
                <h4>Arguments:</h4>
                {renderArgDesc(data.arguments)}
            </div>
            {message && (
                <p
                    className={`text-center ${isError ? 'bg-red-500' : 'bg-blue-500'} text-white p-2`}
                >
                    {message}
                </p>
            )}

            <div className="text-right">
                <button
                    onClick={executeHandler}
                    className="border bg-blue-500 p-3 text-white"
                >
                    Execute
                </button>
            </div>
        </div>
    );
};

export default Utility;
