import {
    CellValueChangedEvent,
    ColDef,
    ColGroupDef,
    ColumnGroupShowType,
    GridApi,
    ValueGetterParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useGetCards, useUpdateCard } from '../services/auditService';
import { cardStatusMap, worksheetStatusMap } from '../services/boardTransformer';
import { useGetAuditReviews } from '../services/reviewService';
import { titleToKeyMapping } from '../services/standardMappingTransformer';
import { AuditType, TransformedAuditBoard } from '../Types/Audit';
import { Card, Row } from '../Types/Card';
import { Review, REVIEW_STATUS, ReviewType } from '../Types/Review';
import { StandardMapping } from '../Types/Standards';
import GridCellAttachments from './GridCellAttachments';
import GridCellBadge from './GridCellBadge';
import GridCellBadgeList from './GridCellBadgeList';
import GridCellIframeContainer from './GridCellIframeContainer';
import GridCellIframeDrawer from './GridCellIframeDrawer';
import GridCellResult from './GridCellResults';
import GridCellReview from './GridCellReview';
import { BadgeColors } from './ui/Badge';

type ColDefOrGroupDef = ColDef | ColGroupDef;
interface AuditGridProps {
    audit?: TransformedAuditBoard;
}

interface ValueFormatterParams {
    value: Review | null;
}

interface ColumnDefinition {
    headerName: string;
    field: string;
}

const badgeTestStatusColorMapping = (value: string) => {
    if (value === 'Incomplete') {
        return BadgeColors.DEFAULT;
    } else if (value === 'Under Review') {
        return BadgeColors.INFO;
    } else if (value === 'Approved') {
        return BadgeColors.ACCEPTED;
    } else if (value === 'Exceptions') {
        return BadgeColors.ERROR;
    } else if (value === 'Descoped') {
        return BadgeColors.DESCOPED;
    }
};

const testingStatusColorMapping = (value: string) => {
    if (value === 'Control Gap') {
        return BadgeColors.ERROR;
    } else if (value === 'Implemented') {
        return BadgeColors.INFO;
    } else if (value === 'For Review') {
        return BadgeColors.WARNING;
    } else if (value === 'Audit Query') {
        return BadgeColors.INFO;
    } else if (value === 'Accepted') {
        return BadgeColors.ACCEPTED;
    }
};

const generateStandardColumns = (auditTypes: AuditType[]): ColDefOrGroupDef[] => {
    // Initialize SOC 2 group with soc2All and ensure children is defined
    const columnDefinitions = generateStandardsColumnDefinitions(auditTypes);
    const standards: ColGroupDef = {
        headerName: 'Standards',
        children: [
            {
                headerName: 'All Standards',
                field: 'standardMapping.all',
                cellRenderer: GridCellBadgeList,
                columnGroupShow: 'closed' as ColumnGroupShowType,
                wrapText: true,
            },
            ...columnDefinitions.map(({ headerName, field }) => {
                return {
                    headerName,
                    field,
                    columnGroupShow: 'open' as ColumnGroupShowType,
                    cellRenderer: GridCellBadgeList,
                    wrapText: true,
                    editable: true,
                    cellEditor: 'agSmallTextCellEditor',
                    cellEditorPopup: false,
                    cellDataType: 'array',
                };
            }),
        ],
        openByDefault: true,
    };
    return [standards];
};

const keyToTitleMapping: Record<keyof StandardMapping, string> = Object.keys(
    titleToKeyMapping,
).reduce(
    (acc, title) => {
        const key = titleToKeyMapping[title as keyof typeof titleToKeyMapping];
        acc[key] = title;
        return acc;
    },
    {} as Record<keyof StandardMapping, string>,
);

const generateStandardsColumnDefinitions = (auditTypes: string[]): ColumnDefinition[] => {
    const filteredKeys = (
        Object.values(titleToKeyMapping) as Array<keyof StandardMapping>
    ).filter((key) => auditTypes.includes(keyToTitleMapping[key]));

    return filteredKeys.map((key) => ({
        headerName: keyToTitleMapping[key],
        field: `standardMapping.${key}`,
    }));
};

const mergeReviewsOntoCards = (cards: Card[], reviews: Review[]): Row[] => {
    const updatedData = cards.map((card) => {
        const filteredReviews = reviews?.filter(
            (review: Review) => review.reviewableId === card.id,
        );

        const reviewsByType = filteredReviews?.reduce(
            (acc: Partial<Record<ReviewType, Review>>, review) => {
                if (review.reviewType && ReviewType[review.reviewType]) {
                    acc[review.reviewType] = review;
                }
                return acc;
            },
            {},
        );

        return {
            ...card,
            ...reviewsByType,
        } as Row;
    });
    return updatedData;
};

const generateAuditStandards = (auditTypes: string[], rows: Row[]): Row[] => {
    const updatedRows = rows.map((card) => {
        const filteredStandardKeys = (
            Object.values(titleToKeyMapping) as Array<keyof StandardMapping>
        ).filter((key) => auditTypes.includes(keyToTitleMapping[key]));

        const filteredCardStandards = Object.entries(card.standardMapping)
            .map(([key, value]) => {
                return filteredStandardKeys.includes(key as keyof StandardMapping)
                    ? value
                    : null;
            })
            .filter(Boolean)
            .flat();
        const standardMapping = {
            standardMapping: {
                ...card.standardMapping,
                all: filteredCardStandards,
            },
        };
        return { ...card, ...standardMapping };
    });
    return updatedRows;
};

const SECTION_OPTIONS = [
    'Control Environment',
    'Information & Communication',
    'Risk Management',
    'Vendor Management',
    'Change Management',
    'System Security',
    'System Operations',
    'Physical Security',
    'Confidentiality',
    'Availability',
    'Processing Integrity',
    'Privacy',
    'Social and Community Impact',
    'Environmental Impact',
];

interface ValueFormatterParams {
    value: Review | null;
}

interface ArrayValueFormatterParams {
    value?: string[] | [];
    newValue?: string;
}

export default function AuditGrid({ audit }: AuditGridProps) {
    const {
        data: cards,
        error: _cardsError,
        isLoading: cardsLoading,
    } = useGetCards(audit?.id);
    const {
        data: reviewData,
        error: _reviewError,
        isLoading: _reviewLoading,
    } = useGetAuditReviews(audit?.id);
    const updateCardMutation = useUpdateCard();
    const [rowData, setRowData] = useState<Row[]>([]);
    const defaultColDef = useMemo<ColDef>(() => {
        return {
            width: 150,
            filter: 'agTextColumnFilter',
            wrapText: true,
            autoHeight: true,
            floatingFilter: true,
            filterParams: {
                closeOnApply: true,
                buttons: ['cancel', 'reset', 'apply'],
            },
        };
    }, []);
    const gridApi = useRef<GridApi | null>(null);

    useEffect(() => {
        if (Array.isArray(cards)) {
            let updatedData = mergeReviewsOntoCards(cards as Card[], reviewData || []);
            updatedData = generateAuditStandards(audit?.auditType || [], updatedData);
            setRowData(updatedData);
        }
    }, [cards, reviewData, audit]);

    const dataTypeDefinitions = useMemo(() => {
        return {
            review: {
                extendsDataType: 'object',
                baseDataType: 'object' as const,
                valueFormatter: (params: ValueFormatterParams) =>
                    params.value == null ? '' : params.value.status,
                dataTypeMatcher: (value: unknown): boolean =>
                    typeof value === 'object' && value !== null && 'status' in value,
            },
            array: {
                extendsDataType: 'object',
                baseDataType: 'object' as const,
                valueFormatter: (params: ArrayValueFormatterParams) =>
                    params.value == null ? '' : params.value.join(','),
                valueParser: (params: ArrayValueFormatterParams) => {
                    const value = (params.newValue ?? '')
                        .split(/[ ,]+/)
                        .map((v: string) => v.trim());
                    return value;
                },
                dataTypeMatcher: (value: unknown): boolean =>
                    Array.isArray(value) && value !== null,
            },
        };
    }, []);

    const colDefs = useMemo<ColDefOrGroupDef[]>(() => {
        return [
            {
                headerName: 'Ref ID',
                field: 'refId',
                width: 120,
                wrapText: true,
                cellRenderer: GridCellIframeContainer,
                sort: 'asc',
                pinned: 'left',
            },
            {
                headerName: 'Section',
                field: 'section',
                width: 150,
                editable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: SECTION_OPTIONS.sort(),
                },
            },
            {
                headerName: 'Control Description',
                field: 'controlDescription',
                wrapText: true,
                autoHeight: true,
                width: 250,
                editable: true,
                cellEditor: 'agLargeTextCellEditor',
                cellEditorPopup: true,
            },
            {
                headerName: 'Importance',
                field: 'importance',
                cellRenderer: GridCellBadge,
                width: 120,
                editable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ['Low', 'Moderate', 'High', 'Must-have'],
                },
            },
            {
                headerName: 'Frequency',
                field: 'frequency',
                cellRenderer: GridCellBadge,
                width: 120,
                editable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: [
                        'Continuous',
                        'Daily',
                        'Quarterly',
                        'Twice-yearly',
                        'Annual',
                        'New hires',
                        'Terminated employees',
                        'New Customers',
                        'New vendors',
                        'Current vendors',
                        'Terminated vendors',
                        'Failed job schedules',
                        'Incidents',
                        'Change releases',
                        'Emergency changes',
                        'Changes to automated job schedules',
                    ],
                },
            },
            {
                headerName: 'Board Status',
                field: 'status',
                cellRenderer: GridCellBadge,
                cellRendererParams: {
                    colorMapping: testingStatusColorMapping,
                },
                editable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: Object.values(cardStatusMap),
                },
            },
            {
                headerName: 'Attachments',
                field: 'attachments',
                cellRenderer: GridCellAttachments,
                cellRendererParams: {
                    prop: 'attachments',
                },
                width: 250,
            },
            {
                headerName: 'Testing Status',
                field: 'worksheetStatus',
                cellRenderer: GridCellBadge,
                cellRendererParams: {
                    colorMapping: badgeTestStatusColorMapping,
                },
                editable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: Object.values(worksheetStatusMap),
                },
            },
            {
                headerName: 'Service Auditor Tests',
                field: 'serviceAuditorTests',
                editable: true,
                wrapText: true,
                autoHeight: true,
                width: 250,
                cellEditor: 'agLargeTextCellEditor',
                cellEditorPopup: true,
            },
            {
                headerName: 'Results',
                field: 'results',
                cellRenderer: GridCellResult,
                cellRendererParams: {
                    prop: 'results',
                },
                width: 250,
            },
            {
                headerName: 'Test Results',
                field: 'testingComments',
                wrapText: true,
                autoHeight: true,
                width: 250,
                editable: true,
                cellEditor: 'agLargeTextCellEditor',
                cellEditorPopup: true,
            },
            ...generateStandardColumns(audit?.auditType || []),
            {
                headerName: 'Senior Review',
                valueGetter: (params: ValueGetterParams) =>
                    params.data[ReviewType.supervisor]?.status ||
                    REVIEW_STATUS.notStarted,
                cellRenderer: GridCellReview,
                cellRendererParams: {
                    prop: {
                        type: ReviewType.supervisor,
                    },
                },
                wrapText: true,
                autoHeight: true,
                minWidth: 220,
            },
            {
                headerName: 'Manager Review',
                valueGetter: (params: ValueGetterParams) =>
                    params.data[ReviewType.manager]?.status || REVIEW_STATUS.notStarted,
                cellRenderer: GridCellReview,
                cellRendererParams: {
                    prop: {
                        type: ReviewType.manager,
                    },
                },
                wrapText: true,
                autoHeight: true,
                minWidth: 220,
            },
            {
                headerName: 'AI Audit',
                field: 'refId',
                wrapText: true,
                cellRenderer: GridCellIframeDrawer,
                sort: 'asc',
            },
            //{ headerName: 'Client Comments', field: 'clientComments' },
        ];
    }, [audit]);

    const LoadingOverlay: React.FC = () => (
        <div className="ag-overlay-loading-center">
            Please wait while your rows are loading
        </div>
    );

    const NoRowsOverlay: React.FC = () => <div>No data available to display</div>;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function setNestedValue(obj: any, path: string, value: any) {
        const keys = path.split('.');
        let current = obj;
        for (let i = 0; i < keys.length - 1; i++) {
            if (!current[keys[i]]) {
                current[keys[i]] = {};
            }
            current = current[keys[i]];
        }
        current[keys[keys.length - 1]] = value;
    }

    const handleCellEditEnd = (event: CellValueChangedEvent) => {
        console.log('handle cell edit running: ', {
            old: event.oldValue,
            new: event.newValue,
        });
        const oldCard = event.data as Card;
        const updatedCard: Partial<Card> = {};
        const colId = event.column.getColId();
        setNestedValue(updatedCard, colId, event.newValue);
        updateCardMutation.mutate({
            cardId: oldCard.id,
            card: updatedCard,
        });
    };

    return (
        <div className="ag-theme-quartz h-full w-full">
            <AgGridReact
                rowData={cardsLoading ? null : rowData}
                columnDefs={colDefs}
                defaultColDef={defaultColDef}
                loadingOverlayComponent={LoadingOverlay}
                noRowsOverlayComponent={NoRowsOverlay}
                reactiveCustomComponents
                dataTypeDefinitions={dataTypeDefinitions}
                onCellValueChanged={handleCellEditEnd}
                getRowId={(params) => params.data.id}
                onGridReady={(params) => (gridApi.current = params.api)}
            />
        </div>
    );
}
