import './App.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect } from 'react';

import Container from './components/Container';
import { Route } from './components/CustomRouter';
import LexiAudit from './pages/LexiAudit';
import Pillar from './pages/Pillar';
import Settings from './pages/Settings';
import Utilities from './pages/Utilities';
import Worksheet from './pages/Worksheet';
import { DrawerProvider } from './providers/Drawer';
import { ModalProvider } from './providers/Modal';
import { fetchUserData } from './services/authService';
import { isLoading } from './state/auth';
const queryClient = new QueryClient();

function App() {
    useEffect(() => {
        fetchUserData();
    }, []);

    const routes = [
        { path: '/pillar', component: <Pillar /> },
        { path: '/lexi', component: <LexiAudit /> },
        { path: '/', component: <Worksheet /> },
        { path: '/settings', component: <Settings /> },
        { path: '/utilities', component: <Utilities /> },
    ];

    if (isLoading.value) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="flex items-center space-x-3">
                    <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500"></div>
                    <span className="text-lg font-semibold">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <QueryClientProvider client={queryClient}>
            <DrawerProvider>
                <ModalProvider>
                    <Container>
                        {routes.map((route) => (
                            <Route key={route.path} path={route.path}>
                                {route.component}
                            </Route>
                        ))}
                    </Container>
                </ModalProvider>
            </DrawerProvider>
        </QueryClientProvider>
    );
}

export default App;
